/*! js-cookie v3.0.5 | MIT */
!function(e,t){"object"==typeof exports&&"undefined"!=typeof module?module.exports=t():"function"==typeof define&&define.amd?define(t):(e="undefined"!=typeof globalThis?globalThis:e||self,function(){var n=e.Cookies,o=e.Cookies=t();o.noConflict=function(){return e.Cookies=n,o}}())}(this,(function(){"use strict";function e(e){for(var t=1;t<arguments.length;t++){var n=arguments[t];for(var o in n)e[o]=n[o]}return e}var t=function t(n,o){function r(t,r,i){if("undefined"!=typeof document){"number"==typeof(i=e({},o,i)).expires&&(i.expires=new Date(Date.now()+864e5*i.expires)),i.expires&&(i.expires=i.expires.toUTCString()),t=encodeURIComponent(t).replace(/%(2[346B]|5E|60|7C)/g,decodeURIComponent).replace(/[()]/g,escape);var c="";for(var u in i)i[u]&&(c+="; "+u,!0!==i[u]&&(c+="="+i[u].split(";")[0]));return document.cookie=t+"="+n.write(r,t)+c}}return Object.create({set:r,get:function(e){if("undefined"!=typeof document&&(!arguments.length||e)){for(var t=document.cookie?document.cookie.split("; "):[],o={},r=0;r<t.length;r++){var i=t[r].split("="),c=i.slice(1).join("=");try{var u=decodeURIComponent(i[0]);if(o[u]=n.read(c,u),e===u)break}catch(e){}}return e?o[e]:o}},remove:function(t,n){r(t,"",e({},n,{expires:-1}))},withAttributes:function(n){return t(this.converter,e({},this.attributes,n))},withConverter:function(n){return t(e({},this.converter,n),this.attributes)}},{attributes:{value:Object.freeze(o)},converter:{value:Object.freeze(n)}})}({read:function(e){return'"'===e[0]&&(e=e.slice(1,-1)),e.replace(/(%[\dA-F]{2})+/gi,decodeURIComponent)},write:function(e){return encodeURIComponent(e).replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,decodeURIComponent)}},{path:"/"});return t}));

customElements.define(
    "sticky-ribbon",
    class extends HTMLElement {
    constructor()
    {
        super();

        this.attachShadow({mode: "open"});

        const
            fragment = document.createRange().createContextualFragment(`
<div class="root">
    <div class="wrapper">
        <div class="content">
            ${this.innerHTML}
            <a class="cta"></a>
        </div>
        <a class="close">
            X
        </a>
    </div>
</div>
`),
            style = document.createElement("style")
        ;

        style.textContent = `
.root {
    background: ${this.getAttribute('data-background-color')};
    border: 2px solid ${this.getAttribute('data-background-color')};
    color: ${this.getAttribute('data-text-color')};
    position: relative;
    width: 100%;
}

.wrapper {
    align-items: center;
    display: flex;
    min-height: 35px;
    justify-content: center;
    padding: 0 8px;
    position: relative;
}

.content {
    font-size: 14px;
    line-height: 1.3;
    padding-right: 24px;
    text-align: center;
}

a {
    line-height: 1;
    text-decoration: none;
}

p {
    display: inline;
    margin: 0;
    padding: 0;
}

.cta {
    background: ${this.getAttribute('data-cta-background-color')};
    border-radius: .3rem;
    color: ${this.getAttribute('data-cta-text-color')};
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin: 0 .8rem;
    padding: .5rem 1.2rem;
}

.close:hover,
.cta:hover {
    opacity: 0.9;
}

.close {
    color: ${this.getAttribute('data-close-icon-color')};
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    text-align: center;
    right: 0;
    top: 8px;
    width: 40px;
    z-index: 1;
}
`;
        this.shadowRoot.appendChild(style);
        this.shadowRoot.appendChild(fragment.cloneNode(true));
        this.shadowRoot.querySelector('.cta').setAttribute('href', this.getAttribute('data-cta-link'));
        this.shadowRoot.querySelector('.cta').innerHTML = this.getAttribute('data-cta-text');
    }

    connectedCallback()
    {
        this.shadowRoot.querySelector('.close').addEventListener("click", e => {
            jQuery(this.shadowRoot.querySelector('.root')).hide();
            Cookies.set('ribbon-close-' + this.getAttribute('data-id'), true, { expires: 7 });
            jQuery(window).trigger('resize');
        });

        jQuery(window).trigger('resize');

        let
            s=document.querySelector(".header"),
            e=document.querySelector(":root")
        ;
        e.style.setProperty("--header-height",`${s.getBoundingClientRect().height}px`);
    }
    }
);

jQuery( document ).ready(async() => {
    const
        response = await fetch("/wp-json/wp/v2/ribbons"),
        ribbons = await response.json()
    ;

    if (!ribbons.length)
        return
    ;

    const ribbonResult = [];
    for (let k in ribbons) {
        if (!Cookies.get('ribbon-close-' + ribbons[k].id))
            ribbonResult.push(ribbons[k]);
    }

    if (!ribbonResult.length)
        return
    ;

    let ribbon;
    for (let k in ribbonResult)
        if (!Cookies.get('ribbon-view-' + ribbonResult[k].id))
            ribbon = ribbonResult[k]
    ;

    if (!ribbon) {
        for (let k in ribbons)
            Cookies.remove('ribbon-view-' + ribbons[k].id)
        ;

        ribbon = ribbons.pop();
    }

    Cookies.set('ribbon-view-' + ribbon.id, true, { expires: 7 });

    const el = jQuery(`<sticky-ribbon is="sticky-ribbon"
     class="sticky-ribbon"
     data-id="${ribbon.id}"
     data-background-color="${ribbon.acf.background_color}"
     data-close-icon-color="${ribbon.acf.close_icon_color}"
     data-cta-background-color="${ribbon.acf.cta_background_color}"
     data-cta-link="${ribbon.acf.cta_link.url}"
     data-cta-text="${ribbon.acf.cta_text}"
     data-text-color="${ribbon.acf.text_color}"
     data-cta-text-color="${ribbon.acf.cta_text_color}">${ribbon.content.rendered}</sticky-ribbon>`);
    jQuery('header.header').prepend(el);
});
